// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #95C7EA;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spinner 0.5s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/cardBook.css"],"names":[],"mappings":"AAAA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC;;AAEA;IACI,oCAAoC;IACpC,0BAA0B;IAC1B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uCAAuC;AAC3C","sourcesContent":["@keyframes spinner {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n.spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-left-color: #95C7EA;\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    animation: spinner 0.5s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
