import React, {useState, useEffect} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {getAllDomaines} from '../apiConfig/services';
import {useNavigate} from "react-router-dom";

function Search({userLogin = "Scrat1313"}) {
    const navigate = useNavigate();
    const [isFocused, setIsFocused] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getAllDomaines();
                setCategories(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories', error);
            }
        };

        fetchCategories();

    }, []);

    // Correction dans Search.js

    const handleSearch = () => {
        // Créer un objet avec les informations de recherche
        const searchData = {
            category_id: selectedCategory || '',
            searchTerm: searchValue || ''
        };

        console.log(JSON.stringify(searchData, null, 2));

        // Correction des variables dans navigate()
        navigate(`/search?query=${searchData.searchTerm}&domaine=${searchData.category_id}`);
    };


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="flex flex-col items-center justify-center pt-8 pb-16 bg-primary px-2 md:px-0">
            {/* Sélecteur de catégorie pour les écrans inférieurs à md */}
            <div className="relative w-full max-w-4xl mx-auto mb-4 md:hidden">
                <div className="relative group">
                    <select
                        className="w-full h-14 pl-12 pr-10 bg-white/10
                            text-white rounded-full border-2 border-blue-200 focus:ring-blue-200
                            outline-none appearance-none transition-all duration-300
                            hover:border-blue-200"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="" disabled>
                            Sélectionner une catégorie
                        </option>
                        {categories.map((category) => (
                            <option
                                key={category.id_dom}
                                value={category.nom_dom}
                                className="text-gray-900 bg-white py-2"
                            >
                                {category.nom_dom}
                            </option>
                        ))}
                    </select>

                    <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/70">
                        <CategoryOutlinedIcon className="text-white mr-2"/>
                    </div>

                    <div className="absolute right-4 top-1/2 -translate-y-1/2 text-white/70 pointer-events-none">
                        <KeyboardArrowDownRoundedIcon className="w-5 h-5"/>
                    </div>
                </div>
            </div>

            {/* Barre de recherche principale */}
            <div className="relative flex items-center bg-blue-100 bg-opacity-15 rounded-full
                          border-2 border-blue-300 w-full max-w-4xl mx-auto md:mx-12 lg:mx-12
                          hover:border-blue-400 transition-all duration-300">
                {/* Select catégorie - visible uniquement à partir de md */}
                <div className="relative hidden md:flex items-center px-4 py-2 bg-transparent
                             text-white border-none rounded-l-full h-16 flex-shrink-0">
                    <CategoryOutlinedIcon className="text-white mr-2 lg:hidden"/>
                    <select
                        className={`bg-transparent text-white outline-none h-full px-4 py-2 
                                rounded-l-full transition-all duration-300 cursor-pointer
                                ${isFocused ? 'select-expanded' : ''}`}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        style={{width: '150px'}}
                    >
                        <option value="" className="text-gray-700 hidden lg:block" disabled>
                            Catégorie
                        </option>
                        {categories.map((category) => (
                            <option
                                key={category.id_dom}
                                value={category.nom_dom}
                                className="text-gray-900 bg-white hover:bg-blue-100"
                            >
                                {category.nom_dom}
                            </option>
                        ))}
                    </select>
                </div>

                <input
                    type="search"
                    placeholder="Rechercher un livre..."
                    className="p-6 flex-grow border-none bg-transparent text-white
                             placeholder-gray-300 h-16 outline-none"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                />

                <button
                    onClick={handleSearch}
                    className="flex items-center px-6 py-2 bg-blue-300 text-gray-800
                             rounded-r-full font-medium h-16 flex-shrink-0
                             hover:bg-blue-400 transition-colors duration-300
                             focus:outline-none focus:ring-2 focus:ring-blue-400
                             focus:ring-offset-2 focus:ring-offset-primary"
                >
                    <SearchIcon className="text-gray-800"/>
                    <span className="ml-2 hidden md:inline">Rechercher</span>
                </button>
            </div>
        </div>
    );
}

export default Search;