import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllBooks, getAllVideos, fetchFile } from '../apiConfig/services';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

const CombinedCategories = () => {
    const { encodedCategory } = useParams();
    const [combinedItems, setCombinedItems] = useState([]);
    const [covers, setCovers] = useState({});
    const [loadingCovers, setLoadingCovers] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const booksData = await getAllBooks();
                const videosData = await getAllVideos();

                // Fusionner les données selon nom_dom
                const mergedData = [...booksData, ...videosData].reduce((acc, item) => {
                    const existing = acc.find(el => el.nom_dom === item.nom_dom);
                    if (existing) {
                        existing.items.push(item);
                    } else {
                        acc.push({ nom_dom: item.nom_dom, items: [item] });
                    }
                    return acc;
                }, []);

                setCombinedItems(mergedData);

                // Initialiser les couvertures en état de chargement
                const initialLoadingCovers = mergedData.reduce((acc, domain) => {
                    domain.items.forEach(item => {
                        acc[item.id_sup] = true;
                    });
                    return acc;
                }, {});
                setLoadingCovers(initialLoadingCovers);

                // Récupérer les couvertures pour chaque item
                const coverPromises = mergedData.flatMap(domain =>
                    domain.items.map(async (item) => {
                        if (item.couverture_fiche) {
                            const coverUrl = await fetchFile(item.couverture_fiche, "couverture");
                            return { id_sup: item.id_sup, coverUrl };
                        }
                        return { id_sup: item.id_sup, coverUrl: null };
                    })
                );

                const coversData = await Promise.all(coverPromises);
                const coversDict = coversData.reduce((acc, { id_sup, coverUrl }) => {
                    acc[id_sup] = coverUrl;
                    return acc;
                }, {});

                setCovers(coversDict);
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };
        fetchData();
        console.log("categoryNomDomaine", encodedCategory);
        console.log("combinedItems", combinedItems);
    }, [encodedCategory,combinedItems]);

    const handleImageLoaded = (id_sup) => {
        setLoadingCovers(prevLoadingCovers => ({
            ...prevLoadingCovers,
            [id_sup]: false
        }));
    };

    const handleItemClick = (bookId, typeFiche) => {
        if (typeFiche === "video") {
            navigate(`/videos/${bookId}`);
        } else {
            navigate(`/livres/${bookId}`);
        }
    };

    // Filtrer les items selon la catégorie extraite de l'URL
    const filteredItems = combinedItems.filter(domain =>
        domain.nom_dom === decodeURIComponent(encodedCategory)
    );

    return (
        <div className="container mx-auto px-4 py-4 mt-10 lg:px-[10%]">
            {filteredItems.length > 0 ? (
                filteredItems.map((domain) => (
                    <div key={domain.nom_dom} className="mb-8 w-full">
                        <h2 className="text-xl font-bold mb-4">{domain.nom_dom}</h2>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                            {domain.items.map((item) => (
                                <div
                                    key={item.id_sup}
                                    className="bg-blue-50 rounded-xl overflow-hidden h-80 mb-5 cursor-pointer relative"
                                    onClick={() => handleItemClick(item.id_sup, item.typeFiche)}
                                >
                                    <div className="relative h-56 flex justify-center items-center">
                                        {loadingCovers[item.id_sup] && (
                                            <div
                                                className="spinner border-t-2 border-primary border-solid rounded-full h-12 w-12 animate-spin"></div>
                                        )}
                                        {/* Condition pour afficher l'icône PlayArrow seulement si type_fiche est "video" */}
                                        {item.typeFiche === "video" && (
                                            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                                <PlayArrowRoundedIcon style={{ fontSize: 150 }} className="text-blue-300" />
                                            </div>
                                        )}
                                        <img
                                            src={covers[item.id_sup] || 'fallback-image-url.jpg'}
                                            alt={item.nom_sup}
                                            className={`object-cover w-full h-full ${loadingCovers[item.id_sup] ? 'hidden' : ''}`}
                                            onLoad={() => handleImageLoaded(item.id_sup)}
                                        />
                                    </div>
                                    <div className="p-4">
                                        <h2 className="text-xl font-bold">{item.nom_sup}</h2>
                                        <p className="text-gray-500">{item.nom_auteur}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-gray-500">Aucun élément trouvé pour cette catégorie.</p>
            )}
        </div>
    );
};

export default CombinedCategories;