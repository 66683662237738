import React, {useState, useEffect, useRef} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {fetchFile, getById, initializeVideoStream} from '../apiConfig/services';
import {
    ChevronLeft,
    PlayArrow,
    Pause,
    ExpandMore,
} from "@mui/icons-material";
import {useVerifierHost} from "../hooks/verifierHost";

function VideoDetails() {
    const {videoId} = useParams();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const isVerifierHost = useVerifierHost();

    // États existants
    const [video, setVideo] = useState(null);
    const [coverUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [isLoadingVideo, setIsLoadingVideo] = useState(false);
    // Nouveaux états
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [videoError, setVideoError] = useState(null);

    // Nettoyer la vidéo lors du démontage
    useEffect(() => {
        return () => {
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.removeAttribute('src');
                videoRef.current.load();
            }
        };
    }, []);
    // Charger les détails de la vidéo
    useEffect(() => {
        const fetchVideoDetails = async () => {
            setLoading(true);
            try {
                const videoData = await getById(videoId);
                setVideo(videoData);

                if (videoData.path_fiche1 && videoData.path_fiche1.length > 0) {
                    const firstVideo = videoData.path_fiche1[0];
                    handleVideoSelect(firstVideo.file, 0);
                }
            } catch (error) {
                console.error('Error fetching video details:', error);
                setVideoError('Erreur lors du chargement des détails de la vidéo');
            } finally {
                setLoading(false);
            }
        };

        fetchVideoDetails();
    }, [videoId]);


    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };


    // Gestion du changement de vidéo
    const handleVideoSelect = async (file, index) => {
        if (videoRef.current) {
            videoRef.current.pause();
            setIsPlaying(false);
        }

        setIsLoadingVideo(true);
        setVideoError(null);
        setActiveVideoIndex(index);

        try {
            setSelectedFileUrl(file);
            if (videoRef.current) {
                videoRef.current.load();
            }
        } catch (error) {
            console.error('Erreur lors du chargement de la vidéo:', error);
            setVideoError('Erreur lors du chargement de la vidéo');
        } finally {
            setIsLoadingVideo(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-900">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (!video) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-900">
                <p className="text-white text-xl">Error loading video details.</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            <div className="container mx-auto px-4 py-6">
                {/* Navigation */}
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center text-blue-400 hover:text-blue-300 transition-colors duration-300 mb-6"
                >
                    <ChevronLeft className="mr-1"/>
                    <span>Retour</span>
                </button>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Main Video Section */}
                    {/* Section vidéo principale */}
                    <div className="lg:col-span-2">
                        <div className="relative bg-gray-800 rounded-xl overflow-hidden shadow-2xl">
                            {isLoadingVideo ? (
                                <div className="aspect-video flex justify-center items-center">
                                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                                </div>
                            ) : selectedFileUrl ? (
                                <div className="aspect-video relative">
                                    <video
                                        ref={videoRef}
                                        key={selectedFileUrl}
                                        className="w-full h-full"
                                        controls
                                        playsInline
                                        controlsList="nodownload"
                                        onPlay={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                        onEnded={() => setIsPlaying(false)}
                                        onError={(e) => {
                                            console.error('Erreur vidéo:', e);
                                            setVideoError('Erreur lors de la lecture de la vidéo');
                                            setIsPlaying(false);
                                        }}
                                    >
                                        <source
                                            src={`http://192.168.88.136:9096/stream/video/${selectedFileUrl}`}
                                            type="video/mp4"
                                        />
                                        Votre navigateur ne supporte pas la lecture de vidéos.
                                    </video>
                                    {!isPlaying && !videoError && (
                                        <div className="absolute inset-0 flex items-center justify-center">
                                            <button
                                                onClick={handlePlayPause}
                                                className="bg-blue-500 hover:bg-blue-600 rounded-full p-4 transition-transform transform hover:scale-110 z-10"
                                            >
                                                <PlayArrow/>
                                            </button>
                                        </div>
                                    )}
                                    {videoError && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                            <p className="text-red-500 text-center p-4">
                                                {videoError}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="aspect-video flex justify-center items-center bg-gray-700">
                                    <p className="text-gray-400">Aucune vidéo disponible</p>
                                </div>
                            )}
                        </div>

                        {/* Video Info */}
                        <div className="mt-6 bg-gray-800 rounded-xl p-6 shadow-lg">
                            <h1 className="text-2xl font-bold text-blue-400 mb-2">{video.nom_sup}</h1>
                            <div className="flex flex-wrap items-center text-sm text-gray-400 mb-4">
                                <span>{video.nom_dom}</span>
                                <span className="mx-2">•</span>
                                <span>{video.langue_sup}</span>
                                <span className="mx-2">•</span>
                                <span>Par {video.nom_auteur}</span>
                            </div>

                            {/* Description */}
                            <div className="relative">
                                <p className={`text-gray-300 ${!showFullDescription && 'line-clamp-3'}`}>
                                    {video.desc_sup || 'Aucune description disponible.'}
                                </p>
                                {video.desc_sup && (
                                    <button
                                        onClick={() => setShowFullDescription(!showFullDescription)}
                                        className="text-blue-400 hover:text-blue-300 mt-2 flex items-center"
                                    >
                                        <span>{showFullDescription ? 'Voir moins' : 'Voir plus'}</span>
                                        <ExpandMore
                                            className={`transform transition-transform ${
                                                showFullDescription ? 'rotate-180' : ''
                                            }`}
                                        />
                                    </button>
                                )}
                            </div>

                            {/* Tags */}
                            {video.mot_cle && (
                                <div className="mt-4">
                                    <h3 className="text-sm font-semibold text-gray-400 mb-2">Mots-clés</h3>
                                    <div className="flex flex-wrap gap-2">
                                        {video.mot_cle.split(',').map((tag, index) => (
                                            <span
                                                key={index}
                                                className="px-3 py-1 bg-blue-500 bg-opacity-20 text-blue-400 rounded-full text-sm"
                                            >
                                                {tag.trim()}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Playlist Sidebar */}
                    {/* Playlist Sidebar avec la première vidéo sélectionnée par défaut */}
                    <div className="lg:col-span-1">
                        <div className="bg-gray-800 rounded-xl shadow-lg overflow-hidden">
                            <h2 className="p-4 text-lg font-semibold border-b border-gray-700">
                                Liste de lecture
                            </h2>
                            <div className="divide-y divide-gray-700">
                                {video.path_fiche1?.map((fiche, index) => (
                                    <div
                                        key={fiche.id_fiche}
                                        className={`flex p-4 hover:bg-gray-700 cursor-pointer transition-colors ${
                                            index === activeVideoIndex ? 'bg-gray-700' : ''
                                        }`}
                                        onClick={() => handleVideoSelect(fiche.file, index)}
                                    >
                                        <div className="relative w-40 h-24 flex-shrink-0">
                                            {coverUrl && (
                                                <img
                                                    src={coverUrl}
                                                    alt=""
                                                    className="w-full h-full object-cover rounded-lg"
                                                />
                                            )}
                                            {index === activeVideoIndex && (
                                                <div
                                                    className="absolute inset-0 bg-blue-500 bg-opacity-20 rounded-lg flex items-center justify-center">
                                                    <PlayArrow className="text-white"/>
                                                </div>
                                            )}
                                        </div>
                                        <div className="ml-4 flex-1">
                                            <h3 className="font-medium line-clamp-2">{fiche.title_fiche}</h3>
                                            <p className="text-sm text-gray-400 mt-1">
                                                Partie {index + 1}/{video.path_fiche1.length}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default VideoDetails;