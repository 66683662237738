import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft } from "@mui/icons-material";
import PdfViewer from "../components/PdfViewer";


function PdfViewerPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { pdfUrl, title } = location.state || {};

    if (!pdfUrl) {
        return <div>Aucun PDF à afficher</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100">
            {/* En-tête */}
            <div className="bg-primary p-4">
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center text-white mb-4"
                >
                    <ChevronLeft />
                    <span>Retour</span>
                </button>
            </div>

            {/* Contenu PDF */}
            <div className="container mx-auto p-4 ">
                <div className=" h-[80vh]">
                    <PdfViewer pdfUrl={pdfUrl} title={title} />
                </div>
            </div>
        </div>
    );
}

export default PdfViewerPage;