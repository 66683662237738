import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Livre from "./views/Livre";
import Video from "./views/Video";
import Category from "./views/Category";
import BookDetail from "./components/bookDetails";
import VideoDetail from "./components/videoDetails";
import Login from "./components/Login";
import Register from "./components/Register";
import ResultSearch from "./components/resultsearch";
import ProtectedRoute from "./apiConfig/ProtectedRoute";
import ReadPdf from "./views/ReadPdf";

function App() {
    const location = useLocation();

    // Vérifie si l'utilisateur est sur les pages /login ou /register
    const hideHeaderFooter = location.pathname === "/login" || location.pathname === "/register";

    return (
        <div className="App">
            {!hideHeaderFooter && <Header />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/livres" element={
                    <ProtectedRoute>
                        <Livre />
                    </ProtectedRoute>
                } />
                <Route path="/categories/:encodedCategory" element={
                    <ProtectedRoute>
                        <Category />
                    </ProtectedRoute>
                } />
                <Route path="/videos" element={
                    <ProtectedRoute>
                        <Video />
                    </ProtectedRoute>
                } />
                <Route path="/livres/:bookId" element={
                    <ProtectedRoute>
                        <BookDetail />
                    </ProtectedRoute>
                } />
                <Route path="/videos/:videoId" element={
                    <ProtectedRoute>
                        <VideoDetail />
                    </ProtectedRoute>
                } />
                <Route path="/pdf-viewer" element={
                    <ProtectedRoute>
                        <ReadPdf />
                    </ProtectedRoute>
                }></Route>
                <Route path="/search" element={
                    <ProtectedRoute>
                        <ResultSearch />
                    </ProtectedRoute>
                } />
            </Routes>
            {!hideHeaderFooter && <Footer />}
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;