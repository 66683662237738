import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const AUTH_TOKEN_KEY = 'emilib_token';

/**
 * Vérifie si le site est servi via HTTPS
 * @returns {boolean}
 */
const isSecure = () => window.location.protocol === 'https:';

export const authService = {
    /**
     * Définit le token JWT dans les cookies
     * @param {string} token
     */
    setToken(token) {
        try {
            const decodedToken = jwtDecode(token);
            if (!decodedToken.exp) throw new Error('Token invalide');

            Cookies.set(AUTH_TOKEN_KEY, token, {
                expires: new Date(decodedToken.exp * 1000),  // Expiration basée sur le token JWT
                secure: isSecure(),
                sameSite: 'Strict',
                path: '/'  // Assure la disponibilité sur tout le site
            });
        } catch (error) {
            console.error('Erreur lors de la sauvegarde du token:', error);
        }
    },

    /**
     * Récupère le token JWT depuis les cookies
     * @returns {string|null}
     */
    getToken() {
        const token = Cookies.get(AUTH_TOKEN_KEY);
        if (!token) return null;

        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp < currentTime) {
                this.logout();
                return null;
            }

            return token;
        } catch (error) {
            this.logout();
            return null;
        }
    },

    /**
     * Décode le token JWT s'il est valide
     * @returns {object|null}
     */
    decodeToken() {
        const token = this.getToken();
        return token ? jwtDecode(token) : null;
    },

    /**
     * Vérifie si l'utilisateur est authentifié
     * @returns {boolean}
     */
    isAuthenticated() {
        return !!this.getToken();
    },

    /**
     * Récupère les informations de l'utilisateur depuis le token
     * @returns {object|null}
     */
    getUserInfo() {
        return this.decodeToken();
    },

    /**
     * Déconnecte l'utilisateur en supprimant le token des cookies
     */
    logout() {
        Cookies.remove(AUTH_TOKEN_KEY, { path: '/' });
    }
};
