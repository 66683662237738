import axiosConfig from "./axiosConfig";

export const getAllDomaines = async () => {
    try {
        const response = await axiosConfig.get('metierDocument/domaines');
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des catégories', e);
        throw e;
    }
};

export const getAllDomainesWithLimits = async () => {
    try {
        const response = await axiosConfig.get('metierDocument/domaineslimited');
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des catégories', e);
        throw e;
    }
};

export const getCount = async () => {
    try {
        const response = await axiosConfig.get('metierDocument/comptes');
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des catégories', e);
        throw e;
    }
}

export const getAllBooks = async () => {
    try {
        const response = await axiosConfig.get('metierDocument/livres');
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des livres', e);
        throw e;
    }
};

export const getAllBooksWithlimits = async () => {
    try {
        const response = await axiosConfig.get('metierDocument/livresDashboard');
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des livres', e);
        throw e;
    }
};

export const getAllResultSearch = async (query, domaine) => {
    try {
        const response = await axiosConfig.get(`servicesearch/search?query=${query}&domaine=${domaine}`);
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des livres', e);
        throw e;
    }
}

export const getById = async (id_sup) => {
    try {
        const response = await axiosConfig.get(`metierDocument/getById/${id_sup}`);
        return response.data
    } catch (e) {
        console.error('Erreur lors de la sélection du livre', e);
        throw e;
    }
}

export const getAllVideos = async () => {
    try {
        const response = await axiosConfig.get('metierDocument/videos');
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des videos', e);
        throw e;
    }
};

export const getAllVideosWithLimits = async () => {
    try {
        const response = await axiosConfig.get('metierDocument/videosDashboard');
        return response.data;

    } catch (e) {
        console.error('Erreur lors de la récupération des videos', e);
        throw e;
    }
};

export const fetchFile = async (fileName, fileType) => {
    try {
        const response = await axiosConfig.get(`/fileSave/download/${encodeURIComponent(fileType)}/${encodeURIComponent(fileName)}`, {
            responseType: 'blob'
        });

        if (response.status !== 200) {
            throw new Error("Erreur lors du téléchargement du fichier");
        }

        const fileUrl = URL.createObjectURL(response.data);
        return fileUrl;
    } catch (error) {
        console.error("Erreur:", error);
        return null;
    }
};

export const getDataUser = async (matricule) => {
    try {
        const response = await axiosConfig.get(`serviceauth/client/${matricule}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user data', error);
        throw error;
    }
};

export const saveUser = async (im_stud, etud_nom, etud_prenom, etud_mail, etud_num_tel, password_stud) => {
    try {
        const response = await axiosConfig.post(`serviceauth/registerClient`,
            {
                im_stud: im_stud,
                etud_nom: etud_nom,
                etud_prenom: etud_prenom,
                etud_mail: etud_mail,
                etud_num_tel: etud_num_tel,
                password_stud: password_stud
            });
        return response.data;
    } catch (error) {
        console.error('Error saving user data', error);
        throw error;
    }
};

export const login = async (im_stud, password_stud) => {
    try {
        const response = await axiosConfig.post(`serviceauth/login`, {
            'user_email': im_stud,
            'user_password': password_stud
        });
        return response.data;
    } catch (error) {
        console.error('Error logging in', error);
        throw error;
    }
};
// export const authenticated = async () => {
//     try {
//         const response = await axiosConfig.get(`serviceauth/authenticated`);
//         return response.data;
//     } catch (error) {
//         console.error('Error checking authentication status', error);
//         throw error;
//     }
// };


// Service de streaming vidéo avec gestion des erreurs
export const getVideoStreamUrl = (videoFile) => {
    if (!videoFile) {
        throw new Error('Nom de fichier vidéo requis');
    }
    return axiosConfig.get(`stream/video/${encodeURIComponent(videoFile)}`);
};


// Fonction pour initialiser le streaming
export const initializeVideoStream = async (videoFile, setError) => {
    try {
        if (!videoFile) {
            throw new Error('Nom de fichier vidéo requis');
        }


        // Construire l'URL du stream
        const streamUrl = getVideoStreamUrl(videoFile);


        return streamUrl;
    } catch (error) {
        const errorMessage = error.message || 'Erreur lors de l\'initialisation du stream';
        setError(errorMessage);
        console.error('Erreur streaming:', errorMessage);
        throw error;
    }
};