// export const useVerifierHost = () => {
//    const host = typeof window !== 'undefined' && window.location.origin.includes('192.168.88.')
//        ? 'http://192.168.88.136:8081/api/'
//     : 'https://apigateway.hiqaody.me/api/';
//
//     return host;
// };

export const useVerifierHost = () => {
   const host = 'http://192.168.88.136:9096/';

    return host;
};