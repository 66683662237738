import React, {useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    ChevronRight as ChevronRightRoundedIcon,
    ChevronLeft as ChevronLeftRoundedIcon,
    Clear as ClearRoundedIcon,
    Search as SearchRoundedIcon,
} from '@mui/icons-material';
import {fetchFile, getAllResultSearch} from "../apiConfig/services";
import Search from "./Search";

// Fonction pour récupérer les paramètres de l'URL
const getQueryParams = (param, location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param) || '';
};

const ResultSearch = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const query = getQueryParams('query', location);
    const domaine = getQueryParams('domaine', location);
    const [selectedType, setSelectedType] = useState('All');
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredDomains, setFilteredDomains] = useState([]);
    const [books, setBooks] = useState([]);
    const [covers, setCovers] = useState({});
    const [loadingCovers, setLoadingCovers] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const booksPerPage = 6;

    const filteredBooks = books.filter(book =>
        (selectedType === 'All' || book.typeFiche === selectedType) &&
        (selectedKeywords.length === 0 || selectedKeywords.some(keyword => book.nom_dom.includes(keyword)))
    );

    const displayedBooks = filteredBooks;
    const indexOfLastBook = currentPage * booksPerPage;
    const indexOfFirstBook = indexOfLastBook - booksPerPage;
    const currentBooks = displayedBooks.slice(indexOfFirstBook, indexOfLastBook);
    const totalPages = Math.ceil(displayedBooks.length / booksPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handleBookClick = (bookId) => navigate(`/livres/${bookId}`);

    const handleKeywordChange = (keyword) => {
        setSelectedKeywords(prevKeywords =>
            prevKeywords.includes(keyword)
                ? prevKeywords.filter(kw => kw !== keyword)
                : [...prevKeywords, keyword]
        );
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredDomains(
            Array.from(new Set(books.map(book => book.nom_dom)))
                .filter(domain => domain.toLowerCase().includes(query))
        );
    };

    const handleClearFilters = () => {
        setSelectedKeywords([]);
        setSelectedType('All');
        setSearchQuery('');
        setFilteredDomains([]);
    };

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const booksData = await getAllResultSearch(query, domaine);
                setBooks(booksData);

                const initialLoadingCovers = booksData.reduce((acc, book) => {
                    acc[book.id_sup] = true;
                    return acc;
                }, {});
                setLoadingCovers(initialLoadingCovers);

                const coverPromises = booksData.map(async (book) => {
                    if (book.couverture_fiche) {
                        const coverUrl = await fetchFile(book.couverture_fiche, "couverture");
                        return {id_sup: book.id_sup, coverUrl};
                    }
                    return {id_sup: book.id_sup, coverUrl: null};
                });

                const coversData = await Promise.all(coverPromises);
                const coversDict = coversData.reduce((acc, {id_sup, coverUrl}) => {
                    acc[id_sup] = coverUrl;
                    return acc;
                }, {});

                setCovers(coversDict);
                setFilteredDomains(Array.from(new Set(booksData.map(book => book.nom_dom))));
            } catch (error) {
                console.error('Erreur lors de la récupération des livres', error);
            }
        };

        fetchBooks();
    }, [query, domaine]);

    const handleImageLoaded = (bookId) => {
        setLoadingCovers(prevLoadingCovers => ({
            ...prevLoadingCovers,
            [bookId]: false
        }));
    };

    const bookTypes = [...new Set(books.map(book => book.typeFiche))];

    return (
        <div className="">
            <div className="bg-primary p-10">
                <h1 className="text-5xl text-blue-300 text-center mb-10">
                    Tous les livres correspondant à votre recherche : {query} avec {domaine}
                </h1>
            </div>
            <Search/>

            <div className="container mx-auto flex flex-wrap mt-5 mb-5 px-4  lg:px-[10%]">
                <div className="w-full md:w-1/4 mb-8 md:mb-2 md:pr-8">
                    <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
                        <div className="mb-8">
                            <h3 className="text-lg font-semibold mb-4 text-gray-800">Type de fiche</h3>
                            <div className="flex flex-col gap-2">
                                <button
                                    onClick={() => setSelectedType('All')}
                                    className={`px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-200 
                                    ${selectedType === 'All'
                                        ? 'bg-primary text-white shadow-sm shadow-primary/30'
                                        : 'bg-gray-50 hover:bg-gray-100 text-gray-700'}`}
                                >
                                    Tous les catégories
                                </button>
                                {bookTypes.map(type => (
                                    <button
                                        key={type}
                                        onClick={() => setSelectedType(type)}
                                        className={`px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-200 
                                        ${selectedType === type
                                            ? 'bg-primary text-white shadow-sm shadow-primary/30'
                                            : 'bg-gray-50 hover:bg-gray-100 text-gray-700'}`}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-800">Catégories</h3>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Rechercher une catégorie"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="w-full px-4 py-2.5 rounded-xl bg-gray-50 focus:bg-white focus:outline-none focus:ring-2 focus:ring-primary/30 transition-colors shadow-sm hover:shadow-md"
                                />
                                <SearchRoundedIcon
                                    className="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400"
                                />

                                {searchQuery && (
                                    <div
                                        className="absolute top-full mt-2 left-0 right-0 bg-white border border-gray-200 rounded-xl shadow-lg z-10 max-h-48 overflow-y-auto">
                                        {filteredDomains.map(domain => (
                                            <button
                                                key={domain}
                                                onClick={() => {
                                                    handleKeywordChange(domain);
                                                    setSearchQuery('');  // Clear search input
                                                    setFilteredDomains([]);  // Clear suggestions
                                                }}
                                                className="w-full text-left px-4 py-2.5 hover:bg-primary/10 focus:bg-primary/20 transition-colors rounded-lg"
                                            >
                                                {domain}
                                            </button>
                                        ))}
                                        {filteredDomains.length === 0 && (
                                            <p className="text-gray-500 px-4 py-2.5">Aucune catégorie trouvée</p>
                                        )}
                                    </div>
                                )}
                            </div>

                            {selectedKeywords.length > 0 && (
                                <div className="flex flex-wrap gap-2 mb-4">
                                    {selectedKeywords.map(keyword => (
                                        <span
                                            key={keyword}
                                            className="inline-flex items-center bg-primary/10 text-primary px-3 py-1 rounded-lg text-sm"
                                        >
                                            {keyword}
                                            <button
                                                onClick={() => handleKeywordChange(keyword)}
                                                className="ml-2 hover:text-primary/70"
                                            >
                                                <ClearRoundedIcon className="w-4 h-4"/>
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            )}

                            {(selectedKeywords.length > 0 || selectedType !== 'All' || searchQuery !== '') && (
                                <button
                                    onClick={handleClearFilters}
                                    className="w-full mt-6 px-4 py-2.5 rounded-xl text-sm font-medium
                                    bg-red-50 text-red-600 hover:bg-red-100
                                    transition-all duration-200 flex items-center justify-center gap-2"
                                >
                                    <ClearRoundedIcon className="w-4 h-4"/>
                                    Réinitialiser les filtres
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-3/4 flex flex-col">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                        {currentBooks.map(book => (
                            <div
                                key={book.id_sup}
                                className="bg-blue-50 rounded-xl overflow-hidden shadow-sm transition-all duration-200 hover:shadow-md hover:-translate-y-1 cursor-pointer"
                                onClick={() => handleBookClick(book.id_sup)}
                            >
                                <div className="relative h-56 flex justify-center items-center">
                                    {loadingCovers[book.id_sup] && (
                                        <div
                                            className="spinner border-t-2 border-primary border-solid rounded-full h-12 w-12 animate-spin"></div>
                                    )}
                                    <img
                                        src={covers[book.id_sup] || 'fallback-image-url.jpg'}
                                        alt={book.nom_dom}
                                        className={`object-cover w-full h-full ${loadingCovers[book.id_sup] ? 'hidden' : ''}`}
                                        onLoad={() => handleImageLoaded(book.id_sup)}
                                    />
                                </div>
                                <div className="p-4">
                                    <p className="bg-blue-200 font-medium inline-block py-1 px-3 text-xs rounded-full text-blue-800 mb-2">
                                        {book.nom_dom}
                                    </p>
                                    <h2 className="text-xl font-bold text-gray-800 mb-1">{book.nom_sup}</h2>
                                    <p className="text-gray-600 text-sm">{book.nom_auteur}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {totalPages > 1 && (
                        <div className="flex justify-center mt-8">
                            <nav className="inline-flex rounded-lg shadow-sm">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className={`p-2 rounded-l-lg border border-r-0
                                    ${currentPage === 1
                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                                >
                                    <ChevronLeftRoundedIcon/>
                                </button>

                                {[...Array(totalPages)].map((_, index) => {
                                    const pageNum = index + 1;
                                    const isNearCurrentPage = Math.abs(currentPage - pageNum) <= 1;
                                    const isFirstOrLastPage = pageNum === 1 || pageNum === totalPages;

                                    if (isFirstOrLastPage || isNearCurrentPage) {
                                        return (
                                            <button
                                                key={pageNum}
                                                onClick={() => handlePageChange(pageNum)}
                                                className={`px-4 py-2 border border-r-0
                                                ${currentPage === pageNum
                                                    ? 'bg-primary text-white'
                                                    : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                                            >
                                                {pageNum}
                                            </button>
                                        );
                                    } else if (
                                        (pageNum === currentPage - 2 || pageNum === currentPage + 2) &&
                                        !isFirstOrLastPage
                                    ) {
                                        return (
                                            <span key={pageNum} className="px-3 py-2 text-gray-500">...</span>
                                        );
                                    }
                                    return null;
                                })}

                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className={`p-2 rounded-r-lg border
                                    ${currentPage === totalPages
                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                                >
                                    <ChevronRightRoundedIcon/>
                                </button>
                            </nav>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResultSearch;