import React from 'react';
import Shimmer from "./Shimmer";
import SearchShimmer from "./SearchShimmer";

function TitleShimmer() {
    return (
        <div className="bg-primary text-center pt-24 md:pt-10 sm:pt-10 xs:pt-10 pb-48 md:pb-40 sm:pb-10 xs:pb-10">
          {/* Titre avec effets Shimmer */}
            <div className="relative">
                <Shimmer className="h-12 w-3/4 mx-auto mt-8 md:mt-10 relative"/>
                <Shimmer className="h-12 w-2/4 mx-auto mt-4"/>
            </div>

            <Shimmer className="h-6 w-3/4 mx-auto mt-6 mb-16 2xl:mt-16"/>
            <SearchShimmer />
        </div>
    );
}

export default TitleShimmer;