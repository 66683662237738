import React from 'react';
import Shimmer from './Shimmer';

function SearchShimmer() {
    return (
        <div className="flex flex-col items-center justify-center pt-8 pb-16 bg-primary px-2 md:px-0">
            {/* Barre de recherche principale */}
            <div className="relative flex items-center bg-opacity-15 rounded-full
                          w-full max-w-4xl mx-auto md:mx-12 lg:mx-12">
                <Shimmer className="p-6 flex-grow bg-transparent h-16 outline-none"/>
            </div>
        </div>
    );
}

export default SearchShimmer;