import React from 'react';
import Shimmer from './Shimmer';

function CardMentionShimmer() {
    const cardSize = "w-64 h-72"; // Taille unique pour les cartes

    return (
        <div className="flex justify-center space-x-32 -mt-32">
            <div className={`relative rounded-[40px] ${cardSize}`}>
                <Shimmer className="h-full w-full object-cover rounded-[40px]"/>
            </div>
            <div className={`relative rounded-[40px] ${cardSize}`}>
                <Shimmer className="h-full w-full object-cover rounded-[40px]"/>
            </div>
            <div className={`relative rounded-[40px] ${cardSize}`}>
                <Shimmer className="h-full w-full object-cover rounded-[40px]"/>
            </div>
        </div>
    );
}

export default CardMentionShimmer;