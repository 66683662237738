import React, {useEffect, useState} from 'react';
import {useLocation, Link, useNavigate} from 'react-router-dom';
import Logo from '../assets/images/Logos/EmiLib.png';
import {Link as ScrollLink} from 'react-scroll';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import ScrollToTop from './ScrollToTop';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HeaderShimmer from "./Shimmers/HeaderShimmer";
import {authService} from '../apiConfig/authService';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [userStatus, setUserStatus] = useState('déconnecté');
    const [userInitials, setUserInitials] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const isHome = location.pathname === '/' || location.pathname === '/home';
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const checkAuthStatus = () => {
            if (authService.isAuthenticated()) {
                const userInfo = authService.getUserInfo();
                setUserInfo(userInfo);
                if (userInfo && userInfo.sub) {
                    setUserStatus('connecté');
                    const initials = userInfo.sub.charAt(0).toUpperCase();
                    setUserInitials(initials);
                } else {
                    setUserStatus('déconnecté');
                }
            } else {
                setUserStatus('déconnecté');
                setUserInfo(null);
            }
        };

        checkAuthStatus();
    }, []);

    if (isLoading) {
        return (
            <div className="flex flex-col">
                <HeaderShimmer/>
            </div>
        );
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        document.body.style.overflow = !isMenuOpen ? 'hidden' : 'unset';
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
        document.body.style.overflow = 'unset';
    };

    const handleLoginClick = () => {
        closeMenu();
        navigate('/login');
    };

    const handleRegisterClick = () => {
        closeMenu();
        navigate('/register');
    };

    const confirmLogout = () => {
        setShowLogoutConfirm(true);
    };

    const handleLogoutConfirmed = () => {
        authService.logout();
        setUserStatus('déconnecté');
        closeMenu();
        setShowLogoutConfirm(false);
        navigate('/');
    };

    return (
        <>
            <header className="bg-primary w-full z-50">
                <div className="container mx-auto px-4 lg:px-[10%]">
                    <div className="flex items-center justify-between h-16 md:h-20">
                        {/* Logo */}
                        <Link
                            to="/"
                            className="relative z-10 flex-shrink-0"
                            onClick={closeMenu}
                        >
                            <img
                                src={Logo}
                                alt="Logo"
                                className="h-10 md:h-12 w-auto"
                            />
                        </Link>

                        {/* Navigation Desktop */}
                        <nav className="hidden md:flex items-center space-x-8">
                            {isHome ? (
                                <>
                                    <ScrollLink
                                        to="category"
                                        smooth={true}
                                        duration={500}
                                        className="nav-link hover:cursor-pointer"
                                    >
                                        Catégories
                                    </ScrollLink>
                                    <ScrollLink
                                        to="book"
                                        smooth={true}
                                        duration={500}
                                        className="nav-link hover:cursor-pointer"
                                    >
                                        Livres
                                    </ScrollLink>
                                    <ScrollLink
                                        to="videos"
                                        smooth={true}
                                        duration={500}
                                        className="nav-link hover:cursor-pointer"
                                    >
                                        Vidéos
                                    </ScrollLink>
                                </>
                            ) : (
                                <>
                                    <Link to="/" className="nav-link hover:cursor-pointer">Accueil</Link>
                                    <Link to="/livres" className="nav-link hover:cursor-pointer">Livres</Link>
                                    <Link to="/videos" className="nav-link hover:cursor-pointer">Vidéos</Link>
                                </>
                            )}
                        </nav>

                        {/* Boutons Auth Desktop */}
                        <div className="hidden md:flex items-center space-x-4">
                            {userStatus === 'déconnecté' ? (
                                <>
                                    <button
                                        onClick={handleLoginClick}
                                        className="flex items-center space-x-2 text-gray-200 hover:text-white transition-colors duration-300"
                                    >
                                        <HttpsRoundedIcon className="text-sm"/>
                                        <span>Se connecter</span>
                                    </button>
                                    <button
                                        onClick={handleRegisterClick}
                                        className="px-6 py-2 bg-[#6BA9E6] text-white rounded-full hover:bg-[#5590cc] transition-all duration-300"
                                    >
                                        S'inscrire
                                    </button>
                                </>
                            ) : (
                                <div className="flex items-center space-x-4">
                                    <div className="flex items-center">
                                        <div className="relative group">
                                            <div
                                                className="flex items-center space-x-3 bg-[#F2F2F2] px-4 py-2 rounded-lg cursor-pointer hover:bg-[#E5E5E5] transition-all duration-300">
                                                <div
                                                    className="w-8 h-8 rounded-full bg-[#6BA9E6] flex items-center justify-center">
                                                    <span className="text-white font-medium text-sm">
                                                        {userInitials}
                                                    </span>
                                                </div>
                                                <span className="text-[#162449] font-medium">
                                                    {userInfo?.sub?.split('@')[0]}
                                                </span>
                                            </div>
                                            <div
                                                className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50">
                                                <button
                                                    onClick={confirmLogout}
                                                    className="w-full text-left px-4 py-2 text-[#162449] hover:bg-[#F2F2F2] rounded-lg transition-colors duration-300 flex items-center space-x-2"
                                                >
                                                    <LogoutIcon className="h-5 w-5"/>
                                                    <span>Se déconnecter</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Bouton Menu Mobile */}
                        <button
                            className="md:hidden p-2 text-white focus:outline-none z-50"
                            onClick={toggleMenu}
                        >
                            {isMenuOpen ? <CloseIcon/> : <MenuIcon/>}
                        </button>
                    </div>
                </div>

                {/* Menu Mobile Full Screen */}
                <div
                    className={`fixed inset-0 bg-primary z-40 transition-transform duration-300 ease-in-out ${
                        isMenuOpen ? 'translate-x-0' : 'translate-x-full'
                    }`}
                >
                    <div className="flex flex-col h-screen pt-20 px-6">
                        <nav className="flex flex-col space-y-4">
                            {isHome ? (
                                <>
                                    <ScrollLink
                                        to="category"
                                        smooth={true}
                                        duration={500}
                                        className="mobile-nav-link hover:cursor-pointer"
                                        onClick={closeMenu}
                                    >
                                        Catégories
                                    </ScrollLink>
                                    <ScrollLink
                                        to="book"
                                        smooth={true}
                                        duration={500}
                                        className="mobile-nav-link hover:cursor-pointer"
                                        onClick={closeMenu}
                                    >
                                        Livres
                                    </ScrollLink>
                                    <ScrollLink
                                        to="videos"
                                        smooth={true}
                                        duration={500}
                                        className="mobile-nav-link hover:cursor-pointer"
                                        onClick={closeMenu}
                                    >
                                        Vidéos
                                    </ScrollLink>
                                </>
                            ) : (
                                <>
                                    <Link to="/" className="mobile-nav-link hover:cursor-pointer" onClick={closeMenu}>
                                        Accueil
                                    </Link>
                                    <Link to="/livres" className="mobile-nav-link hover:cursor-pointer"
                                          onClick={closeMenu}>
                                        Livres
                                    </Link>
                                    <Link to="/videos" className="mobile-nav-link hover:cursor-pointer"
                                          onClick={closeMenu}>
                                        Vidéos
                                    </Link>
                                </>
                            )}
                        </nav>

                        {/* Version Mobile des boutons d'authentification */}
                        <div className="mt-auto mb-8 space-y-3">
                            {userStatus === 'déconnecté' ? (
                                <>
                                    <button
                                        onClick={handleLoginClick}
                                        className="w-full py-3 text-gray-200 hover:text-white transition-colors duration-300 flex items-center justify-center space-x-2 hover:bg-white/5 rounded-lg"
                                    >
                                        <HttpsRoundedIcon/>
                                        <span>Se connecter</span>
                                    </button>
                                    <button
                                        onClick={handleRegisterClick}
                                        className="w-full py-3 bg-[#6BA9E6] text-white rounded-lg hover:bg-[#5590cc] transition-all duration-300"
                                    >
                                        S'inscrire
                                    </button>
                                </>
                            ) : (
                                <div className="flex flex-col items-center space-y-4">
                                    <div className="px-6 py-3 w-full">
                                        <div className="flex items-center justify-center space-x-3">
                                            <div
                                                className="w-10 h-10 rounded-full bg-[#6BA9E6] flex items-center justify-center">
                                                <span className="text-white font-medium text-lg">
                                                    {userInitials}
                                                </span>
                                            </div>
                                            <span className="text-[#162449] font-medium">
                                                {userInfo?.sub?.split('@')[0]}
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        onClick={confirmLogout}
                                        className="w-full py-3 text-white flex items-center justify-center space-x-2"
                                    >
                                        <LogoutIcon className="h-5 w-5"/>
                                        <span>Se déconnecter</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <ScrollToTop/>
            </header>

            {/* Modale de confirmation de déconnexion avec Tailwind */}
            {showLogoutConfirm && (
                <div
                    className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 animate-fadeIn">
                    <div
                        className="bg-white/90 backdrop-blur-md rounded-2xl p-8 w-full max-w-sm mx-4 shadow-[0_20px_60px_-15px_rgba(107,169,230,0.3)] border border-white/20 transform transition-all duration-300 ease-out animate-slideUp">
                        <div className="relative">
                            {/* Icône de déconnexion stylisée */}
                            <div className="absolute -top-14 left-1/2 transform -translate-x-1/2">
                                <div
                                    className="w-16 h-16 bg-gradient-to-br from-[#6BA9E6] to-[#1E90FF] rounded-full flex items-center justify-center shadow-lg shadow-blue-200/50 border-4 border-white">
                                    <LogoutIcon className="text-white text-2xl"/>
                                </div>
                            </div>

                            {/* Titre avec effet de dégradé */}
                            <h2 className="text-2xl font-bold text-center mt-6 mb-4 bg-gradient-to-r from-[#162449] to-[#1E90FF] bg-clip-text text-transparent">
                                Confirmer la déconnexion
                            </h2>

                            {/* Message avec style moderne */}
                            <p className="text-[#162449]/80 text-center mb-8 font-medium">
                                Êtes-vous sûr de vouloir vous déconnecter ?
                            </p>

                            {/* Boutons avec effets modernes */}
                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={() => setShowLogoutConfirm(false)}
                                    className="px-6 py-2.5 bg-[#F2F2F2] text-[#162449] rounded-xl font-medium
                    hover:bg-[#E5E5E5] transition-all duration-300 ease-in-out
                    hover:shadow-md hover:scale-105 active:scale-95
                    flex items-center justify-center space-x-2"
                                >
                                    <span>Annuler</span>
                                </button>
                                <button
                                    onClick={handleLogoutConfirmed}
                                    className="px-6 py-2.5 bg-gradient-to-r from-[#6BA9E6] to-[#1E90FF] text-white rounded-xl font-medium
                    hover:shadow-lg hover:shadow-blue-200/50 transition-all duration-300 ease-in-out
                    hover:scale-105 active:scale-95
                    flex items-center justify-center space-x-2"
                                >
                                    <LogoutIcon className="text-lg"/>
                                    <span>Se déconnecter</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Header;