import React from 'react';
import Shimmer from './Shimmer';

const HeaderShimmer = () => {
    return (
        <header className="bg-primary w-full z-50">
            <div className="container mx-auto px-4 lg:px-[10%]">
                <div className="flex items-center justify-between h-16 md:h-20">
                    {/* Logo Shimmer */}
                    <Shimmer className="h-10 md:h-12 w-32 rounded-lg"/>

                    {/* Navigation Desktop Shimmer */}
                    <div className="hidden md:flex items-center space-x-8">
                        {[1, 2, 3].map((item) => (
                            <Shimmer
                                key={item}
                                className="h-4 w-24 rounded-full"
                            />
                        ))}
                    </div>

                    {/* Boutons Auth Desktop Shimmer */}
                    <div className="hidden md:flex items-center space-x-4">
                        <Shimmer className="h-8 w-28 rounded-full"/>
                        <Shimmer className="h-10 w-28 rounded-full"/>
                    </div>

                    {/* Bouton Menu Mobile Shimmer */}
                    <Shimmer className="md:hidden h-10 w-10 rounded-lg"/>
                </div>
            </div>
        </header>
    );
};

export default HeaderShimmer;