import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {EmailOutlined} from "@mui/icons-material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {login} from "../apiConfig/services";
import EmiLib from "../assets/images/Logos/EmiLib.png";
import {authService} from "../apiConfig/authService";
import {jwtDecode} from "jwt-decode";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorTimer, setErrorTimer] = useState(5);

    const handleGoBack = () => navigate("/");
    const handleRegister = () => navigate("/register");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!email || !password) {
            setError('Veuillez remplir tous les champs');
            setIsLoading(false);
            return;
        }

        try {
            const response = await login(email, password);

            if (response.token) {
                try {
                    // Décoder et vérifier le token
                    const decodedToken = jwtDecode(response.token);
                    const currentTime = Date.now() / 1000;

                    if (decodedToken.exp < currentTime) {
                        throw new Error('Token expiré');
                    }

                    // Stocker le token
                    authService.setToken(response.token);

                    // Rediriger vers le dashboard
                    navigate('/');
                } catch (tokenError) {
                    setError('Session invalide, veuillez réessayer');
                }
            } else {
                throw new Error('Token non reçu');
            }
        } catch (err) {
            setError('Identifiants incorrects');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let timer;
        if (error && errorTimer > 0) {
            timer = setInterval(() => {
                setErrorTimer((prev) => prev - 1);
            }, 1000);
        } else if (errorTimer === 0) {
            setError('');
            setErrorTimer(5);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [error, errorTimer]);

    // Reset du timer quand une nouvelle erreur apparaît
    useEffect(() => {
        if (error) {
            setErrorTimer(5);
        }
    }, [error]);

    const errorAnimationClass = error
        ? 'opacity-100 translate-y-0'
        : 'opacity-0 -translate-y-2';

    return (
        <div className="min-h-screen bg-gradient-to-br from-white to-[#F8FAFC] relative">
            {/* Motif de fond éducatif */}
            <div className="absolute inset-0 opacity-[0.03] pointer-events-none">
                <div className="absolute inset-0" style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23162449' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                    backgroundSize: '30px 30px'
                }}/>
            </div>

            <div className="relative min-h-screen flex flex-col lg:flex-row">
                {/* Section gauche - Formulaire */}
                <div className="w-full lg:w-1/2 p-6 lg:p-12 flex items-center justify-center">
                    <div className="w-full max-w-md">
                        {/* Bouton retour */}
                        <button
                            onClick={handleGoBack}
                            className="flex items-center text-[#162449] hover:text-[#6BA9E6] transition-all duration-300 mb-8 group"
                        >
                            <ArrowBackIosRoundedIcon
                                className="mr-1 transform group-hover:-translate-x-1 transition-transform"/>
                            <span>Retour à l'accueil</span>
                        </button>

                        {/* Card du formulaire */}
                        <div className="">
                            <div className="text-center mb-8">
                                <img src={EmiLib} alt="EmiLib" className="h-12 mx-auto mb-6"/>
                                <h2 className="text-2xl font-bold text-[#162449] mb-2">
                                    Espace personnel
                                </h2>
                                <p className="text-gray-600 text-sm">
                                    Connectez-vous pour accéder à vos ressources pédagogiques
                                </p>
                            </div>

                            {/* Message d'erreur avec timer */}
                            <div
                                className={`transition-all duration-300 transform ${errorAnimationClass}`}>
                                {error && (
                                    <div
                                        className="bg-red-50 border-l-4 border-red-500 p-4 rounded-r-lg shadow-lg flex items-start">
                                        <div className="flex-1">
                                            <p className="text-red-600 text-sm flex items-center">
                                                <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                                    <path fillRule="evenodd"
                                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                                {error}
                                            </p>
                                        </div>
                                        <div className="ml-3">
                                            <div className="relative h-6 w-6">
                                                <svg className="h-6 w-6 text-red-500" viewBox="0 0 24 24">
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d={`M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10A10 10 0 0 1 2 12 10 10 0 0 1 12 2v0z`}
                                                        style={{
                                                            transformOrigin: 'center',
                                                            transform: `scale(${errorTimer / 5})`,
                                                            transition: 'transform 1s linear'
                                                        }}
                                                    />
                                                </svg>
                                                <span
                                                    className="absolute inset-0 flex items-center justify-center text-white text-xs font-bold">
                                                    {errorTimer}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <form onSubmit={handleSubmit} className="space-y-6">
                                {/* Email */}
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-[#162449]">
                                        Adresse email
                                    </label>
                                    <div className="relative">
                                        <EmailOutlined
                                            className="absolute left-4 top-1/2 -translate-y-1/2 text-[#6BA9E6]"/>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="w-full pl-12 pr-4 py-3 bg-gray-50 border border-gray-200 rounded-xl
                                                     focus:border-[#6BA9E6] focus:bg-white focus:ring-2 focus:ring-[#6BA9E6]/20
                                                     transition-all duration-300"
                                            placeholder="Entrez votre adresse email"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Mot de passe */}
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-[#162449]">
                                        Mot de passe
                                    </label>
                                    <div className="relative">
                                        <LockOutlinedIcon
                                            className="absolute left-4 top-1/2 -translate-y-1/2 text-[#6BA9E6]"/>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="w-full pl-12 pr-12 py-3 bg-gray-50 border border-gray-200 rounded-xl
                                                     focus:border-[#6BA9E6] focus:bg-white focus:ring-2 focus:ring-[#6BA9E6]/20
                                                     transition-all duration-300"
                                            placeholder="••••••••"
                                            required
                                        />
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-[#6BA9E6]"
                                        >
                                            {showPassword ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                                        </button>
                                    </div>
                                </div>

                                {/* Boutons d'action */}
                                <div className="space-y-4">
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className="w-full py-3 bg-[#162449] text-white rounded-xl font-medium
                                                 transition-all duration-300 transform hover:bg-[#1E90FF]
                                                 disabled:opacity-70 disabled:cursor-not-allowed"
                                    >
                                        {isLoading ? (
                                            <span className="flex items-center justify-center">
                                                <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10"
                                                            stroke="currentColor" strokeWidth="4"/>
                                                    <path className="opacity-75" fill="currentColor"
                                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"/>
                                                </svg>
                                                Connexion en cours...
                                            </span>
                                        ) : "Se connecter"}
                                    </button>

                                    <button
                                        type="button"
                                        onClick={handleRegister}
                                        className="w-full py-3 bg-white text-[#162449] border-2 border-[#162449] rounded-xl
                                                 font-medium transition-all duration-300 hover:bg-gray-50"
                                    >
                                        Créer un compte
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Section droite - Informations */}
                <div className="hidden lg:flex w-1/2 bg-[#162449] p-12">
                    <div className="w-full max-w-lg mx-auto flex flex-col justify-center text-white">
                        <h1 className="text-3xl font-bold mb-6">Bienvenue sur votre plateforme d'apprentissage</h1>
                        <p className="text-white/80 mb-12 text-lg">
                            Accédez à toutes vos ressources pédagogiques et suivez votre progression en temps réel.
                        </p>

                        <div className="grid grid-cols-2 gap-6">
                            {[
                                {
                                    icon: "📚",
                                    title: "Ressources Pédagogiques",
                                    desc: "Accédez à une bibliothèque complète de supports de cours et exercices"
                                },
                                {
                                    icon: "📊",
                                    title: "Suivi Personnalisé",
                                    desc: "Visualisez votre progression et vos résultats en temps réel"
                                },
                                {
                                    icon: "👥",
                                    title: "Espace Collaboratif",
                                    desc: "Échangez avec vos professeurs et autres étudiants"
                                },
                                {
                                    icon: "🎯",
                                    title: "Objectifs d'Apprentissage",
                                    desc: "Définissez et suivez vos objectifs pédagogiques"
                                }
                            ].map((feature, index) => (
                                <div key={index}
                                     className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 border border-white/20
                                              hover:bg-white/20 transition-all duration-300">
                                    <span className="text-3xl mb-4 block">{feature.icon}</span>
                                    <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                                    <p className="text-sm text-white/70">{feature.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;