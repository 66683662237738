import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import EmiLib from '../assets/images/Logos/EmiLib.png'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import {getDataUser, saveUser} from "../apiConfig/services";
import {EmailOutlined, InfoRounded, PhoneOutlined} from "@mui/icons-material";

const Register = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        im_stud: '',
        etud_nom: '',
        etud_prenom: '',
        etud_mail: '',
        etud_num_tel: '',
        password_stud: '',
        confirmPassword_stud: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFirstStepSubmit = async (e) => {
        e.preventDefault();
        // Validation pour la première étape
        if (formData.im_stud <= 7 || formData.im_stud >= 4) {
            setError('Le numéro matricule doit être entre 4 et 6 caractères');
            return;
        }
        const immatricule = formData.im_stud;

        try {
            const accountData = await getDataUser(immatricule);
            console.log(accountData);
            setFormData({
                ...formData,
                etud_nom: accountData.etud_nom,
                etud_prenom: accountData.etud_prenom,
                etud_mail: accountData.etud_mail,
                etud_num_tel: accountData.etud_num_tel
            })
        } catch (err) {
            setError('Le numéro matricule est incorrect');
        }
        setError('');
        setStep(2);
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();

        if (formData.password_stud !== formData.confirmPassword_stud) {
            setError('Les mots de passe ne correspondent pas');
            return;
        }

        try {
            // Logique d'inscription à implémenter
            console.log('Inscription', formData);
            // Enregistrer l'utilisateur dans la base de données
            const response = await saveUser(formData.im_stud, formData.etud_nom, formData.etud_prenom, formData.etud_mail, formData.etud_num_tel, formData.password_stud);
            console.log(response);
            navigate('/login');
        } catch (err) {
            setError('Erreur lors de l\'inscription');
        }
    };

    const handleGoBack = () => {
        navigate("/");
    };

    return (
        <div className="min-h-screen relative">
            {/* Effets d'arrière-plan */}
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                {/* Grille de points */}
                <svg className="absolute w-full h-full opacity-[0.03]">
                    <pattern id="dots" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <circle cx="2" cy="2" r="1" fill="#162449"/>
                    </pattern>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#dots)"/>
                </svg>
            </div>

            <div className="relative min-h-screen flex flex-col lg:flex-row">
                {/* Section gauche - Formulaire */}
                <div className="w-full lg:w-1/2 p-6 lg:p-12 flex items-center justify-center">
                    <div className="w-full max-w-md">
                        {/* Bouton retour */}
                        <button
                            onClick={handleGoBack}
                            className="flex items-center text-sm text-[#162449] hover:text-[#6BA9E6]
                                         transition-all duration-300 mb-2 group"
                        >
                            <ArrowBackIosRoundedIcon
                                className="mr-1"/>
                            <span>Retour à l'accueil</span>
                        </button>

                        {/* Card du formulaire */}
                        <div>
                            <div className="text-center mb-8">
                                <img src={EmiLib} alt="EmiLib" className="h-10 mx-auto mb-6"/>
                                <h2 className="text-xl font-bold bg-gradient-to-r from-[#162449] to-[#1E90FF]
                                               bg-clip-text text-transparent mb-3">
                                    {step === 1 ? 'Créer un compte' : 'Finaliser l\'inscription'}
                                </h2>
                                <p className="text-[#162449]/60 text-sm">
                                    {step === 1
                                        ? 'Commencez par entrer votre numéro d`\'immatriculation'
                                        : 'Vérifiez vos informations et créez votre mot de passe'}
                                </p>
                            </div>

                            {/* Message d'erreur */}
                            {error && (
                                <div className="mb-6 bg-red-50/80 backdrop-blur-sm px-4 py-3 rounded-xl
                                                text-red-600 text-sm border border-red-100 flex items-center gap-2">
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd"
                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    {error}
                                </div>
                            )}

                            {/* Étape 1 - Immatriculation */}
                            {step === 1 && (
                                <form onSubmit={handleFirstStepSubmit} className="space-y-6">
                                    <div className="space-y-2">
                                        <label className="block text-sm font-medium text-[#162449]">
                                            Numéro d'immatriculation
                                        </label>
                                        <div className="relative">
                                            <TagOutlinedIcon
                                                className="absolute left-4 top-1/2 -translate-y-1/2 text-[#6BA9E6]"/>
                                            <input
                                                type="text"
                                                name="im_stud"
                                                value={formData.im_stud}
                                                onChange={handleChange}
                                                className="w-full pl-12 pr-4 py-3 bg-gray-50 border border-gray-200
                                                             rounded-xl text-[#162449] placeholder-[#162449]/40
                                                             focus:border-[#6BA9E6] focus:bg-white focus:ring-2
                                                             focus:ring-[#6BA9E6]/20 transition-all duration-300"
                                                placeholder="Entrez votre numéro d'immatriculation"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        className="w-full py-3 bg-[#162449] text-white rounded-xl font-medium
                                                     transition-all duration-300 transform hover:bg-[#1E90FF]
                                                     hover:scale-[1.02] focus:outline-none focus:ring-2
                                                     focus:ring-[#6BA9E6] focus:ring-offset-2"
                                    >
                                        Continuer
                                    </button>
                                </form>
                            )}

                            {/* Étape 2 - Informations et mot de passe */}
                            {step === 2 && (
                                <form onSubmit={handleFinalSubmit} className="space-y-4"> {/* Réduit l'espacement */}
                                    <div className="grid grid-cols-1 space-y-4"> {/* Utilisation d'une grille 2 colonnes */}
                                        {/* Première ligne : Nom et Prénom */}
                                        <div className="space-y-1"> {/* Réduit l'espacement vertical */}
                                            <label className="block text-sm font-medium text-[#162449]">
                                                Nom
                                            </label>
                                            <div className="relative">
                                                <PersonOutlined
                                                    className="absolute left-3 top-1/2 -translate-y-1/2 text-[#6BA9E6] text-xl"/>
                                                <input
                                                    type="text"
                                                    name="etud_nom"
                                                    value={formData.etud_nom}
                                                    className="w-full pl-10 pr-3 py-2.5 bg-gray-100 border
                                 border-gray-200 rounded-xl text-[#162449]/80 text-sm"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <label className="block text-sm font-medium text-[#162449]">
                                                Prénom
                                            </label>
                                            <div className="relative">
                                                <PersonOutlined
                                                    className="absolute left-3 top-1/2 -translate-y-1/2 text-[#6BA9E6] text-xl"/>
                                                <input
                                                    type="text"
                                                    name="etud_prenom"
                                                    value={formData.etud_prenom}
                                                    className="w-full pl-10 pr-3 py-2.5 bg-gray-100 border
                                 border-gray-200 rounded-xl text-[#162449]/80 text-sm"
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        {/* Deuxième ligne : Email et Téléphone */}
                                        <div className="space-y-1">
                                            <label className="block text-sm font-medium text-[#162449]">
                                                Email
                                            </label>
                                            <div className="relative">
                                                <EmailOutlined
                                                    className="absolute left-3 top-1/2 -translate-y-1/2 text-[#6BA9E6] text-xl"/>
                                                <input
                                                    type="text"
                                                    name="etud_mail"
                                                    value={formData.etud_mail}
                                                    className="w-full pl-10 pr-3 py-2.5 bg-gray-100 border
                                 border-gray-200 rounded-xl text-[#162449]/80 text-sm"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <label className="block text-sm font-medium text-[#162449]">
                                                Téléphone
                                            </label>
                                            <div className="relative">
                                                <PhoneOutlined
                                                    className="absolute left-3 top-1/2 -translate-y-1/2 text-[#6BA9E6] text-xl"/>
                                                <input
                                                    type="text"
                                                    name="etud_num_tel"
                                                    value={formData.etud_num_tel}
                                                    className="w-full pl-10 pr-3 py-2.5 bg-gray-100 border
                                 border-gray-200 rounded-xl text-[#162449]/80 text-sm"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Champs de mot de passe */}
                                    <div className="space-y-2">
                                        <label className="block text-sm font-medium text-[#162449]">
                                            Mot de passe
                                        </label>
                                        <div className="relative">
                                            <LockOutlinedIcon
                                                className="absolute left-4 top-1/2 -translate-y-1/2 text-[#6BA9E6]"/>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                name="password_stud"
                                                value={formData.password_stud}
                                                onChange={handleChange}
                                                className="w-full pl-12 pr-12 py-3 bg-gray-50 border
                                                         border-gray-200 rounded-xl text-[#162449]
                                                         placeholder-[#162449]/40 focus:border-[#6BA9E6]
                                                         focus:bg-white focus:ring-2 focus:ring-[#6BA9E6]/20
                                                         transition-all duration-300"
                                                placeholder="Créez votre mot de passe"
                                                required
                                            />
                                            <button
                                                type="button"
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="absolute right-4 top-1/2 -translate-y-1/2
                                                         text-gray-400 hover:text-[#6BA9E6]"
                                            >
                                                {showPassword
                                                    ? <VisibilityOffOutlinedIcon/>
                                                    : <VisibilityOutlinedIcon/>}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="space-y-2">
                                        <label className="block text-sm font-medium text-[#162449]">
                                            Confirmer le mot de passe
                                        </label>
                                        <div className="relative">
                                            <LockOutlinedIcon
                                                className="absolute left-4 top-1/2 -translate-y-1/2 text-[#6BA9E6]"/>
                                            <input
                                                type={showConfirmPassword ? "text" : "password"}
                                                name="confirmPassword_stud"
                                                value={formData.confirmPassword_stud}
                                                onChange={handleChange}
                                                className="w-full pl-12 pr-12 py-3 bg-gray-50 border
                                                         border-gray-200 rounded-xl text-[#162449]
                                                         placeholder-[#162449]/40 focus:border-[#6BA9E6]
                                                         focus:bg-white focus:ring-2 focus:ring-[#6BA9E6]/20
                                                         transition-all duration-300"
                                                placeholder="Confirmez votre mot de passe"
                                                required
                                            />
                                            <button
                                                type="button"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                className="absolute right-4 top-1/2 -translate-y-1/2
                                                         text-gray-400 hover:text-[#6BA9E6]"
                                            >
                                                {showConfirmPassword
                                                    ? <VisibilityOffOutlinedIcon/>
                                                    : <VisibilityOutlinedIcon/>}
                                            </button>
                                        </div>
                                    </div>

                                    {/* Information additionnelle */}
                                    <div className="bg-blue-50/50 p-3 rounded-xl mt-4 mb-6">
                                        <div className="flex items-start space-x-3">
                                            <InfoRounded className="text-[#6BA9E6] mt-0.5"/>
                                            <div>
                                                <p className="text-sm text-[#162449]/80">
                                                    Ces informations ont été récupérées automatiquement.
                                                    Si vous constatez une erreur, veuillez contacter l'administration.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Boutons de navigation */}
                                    <div className="flex gap-4 pt-2">
                                        <button
                                            type="button"
                                            onClick={() => setStep(1)}
                                            className="w-1/2 py-2.5 bg-white text-[#162449] border-2
                         border-[#162449] rounded-xl font-medium
                         transition-all duration-300 hover:bg-gray-50"
                                        >
                                            Retour
                                        </button>
                                        <button
                                            type="submit"
                                            className="w-1/2 py-2.5 bg-[#162449] text-white rounded-xl
                         font-medium transition-all duration-300
                         transform hover:bg-[#1E90FF]"
                                        >
                                            Continuer
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>

                {/* Section droite - Contenu informatif */}
                <div
                    className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-[#162449] to-[#1E90FF] p-12 relative overflow-hidden">
                    {/* Motifs décoratifs */}
                    <div className="absolute inset-0">
                        <div className="absolute inset-0" style={{
                            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(255,255,255,0.1) 1px, transparent 0)',
                            backgroundSize: '40px 40px'
                        }}></div>
                    </div>

                    <div className="relative w-full max-w-lg mx-auto flex flex-col justify-center text-white">
                        <h1 className="text-4xl font-bold mb-6">Bienvenue sur EmiLib</h1>
                        <p className="text-xl text-white/80 mb-12">
                            Votre bibliothèque numérique personnelle pour un accès illimité aux ressources pédagogiques.
                        </p>

                        <div className="grid grid-cols-2 gap-6">
                            {[
                                {
                                    icon: "📚",
                                    title: "Ressources Illimitées",
                                    desc: "Accédez à une vaste collection de documents"
                                },
                                {
                                    icon: "🔒",
                                    title: "Espace Sécurisé",
                                    desc: "Protection optimale de vos données"
                                },
                                {
                                    icon: "📱",
                                    title: "Multi-plateforme",
                                    desc: "Accessible sur tous vos appareils"
                                },
                                {
                                    icon: "⚡",
                                    title: "Performance",
                                    desc: "Navigation rapide et intuitive"
                                }
                            ].map((feature, index) => (
                                <div key={index}
                                     className="bg-white/10 backdrop-blur-sm rounded-2xl p-6 border border-white/20
                                                  hover:bg-white/20 transition-all duration-300 group">
                                        <span className="text-3xl mb-4 block transform group-hover:scale-110
                                                      transition-transform duration-300">
                                            {feature.icon}
                                        </span>
                                    <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                                    <p className="text-sm text-white/70">{feature.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;