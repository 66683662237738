import React, {useState, useEffect, useRef, createContext, useContext} from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';

// Contexte pour l'outline
const OutlineContext = createContext();

// Composant OutlineItem simplifié
const OutlineItem = ({item, level = 0}) => {
    const {handleOutlineClick} = useContext(OutlineContext);

    return (
        <div style={{marginLeft: `${level * 20}px`}}>
            <button
                onClick={() => handleOutlineClick(item)}
                className="text-left hover:text-blue-600 hover:bg-blue-50 py-1 px-2 w-full text-sm rounded"
            >
                {item.title}
            </button>
            {item.items?.map((child, index) => (
                <OutlineItem key={index} item={child} level={level + 1}/>
            ))}
        </div>
    );
};

// Composants des boutons
const IconButton = ({onClick, disabled, icon, label}) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`p-2 rounded-lg transition-colors ${
            disabled ? 'opacity-50' : 'hover:bg-gray-100'
        }`}
        aria-label={label}
    >
        {icon}
    </button>
);

// Composant principal
const PdfViewer = ({pdfUrl, title}) => {
    // États essentiels
    const [pdfState, setPdfState] = useState({
        numPages: null,
        currentPage: 1,
        pdfDoc: null,
        outline: [],
        scale: 1.5,
        loading: true
    });
    const [isOutlineOpen, setIsOutlineOpen] = useState(true);
    const [isSpeaking, setIsSpeaking] = useState(false);

    // Refs
    const canvasRef = useRef(null);
    const renderTaskRef = useRef(null);
    const containerRef = useRef(null);
    const utteranceRef = useRef(null);

    // Protection contre la copie
    useEffect(() => {
        const preventActions = (e) => {
            if (e.ctrlKey && ['s', 'p', 'c'].includes(e.key)) e.preventDefault();
        };

        document.addEventListener('keydown', preventActions);
        document.addEventListener('contextmenu', e => e.preventDefault());
        document.addEventListener('copy', e => e.preventDefault());

        return () => {
            document.removeEventListener('keydown', preventActions);
            document.removeEventListener('contextmenu', e => e.preventDefault());
            document.removeEventListener('copy', e => e.preventDefault());
        };
    }, []);

    // Chargement du PDF
    useEffect(() => {
        let isSubscribed = true;

        const loadPdf = async () => {
            setPdfState(prev => ({...prev, loading: true}));
            try {
                const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
                if (!isSubscribed) return;

                const outline = await pdf.getOutline() || [];
                setPdfState(prev => ({
                    ...prev,
                    pdfDoc: pdf,
                    numPages: pdf.numPages,
                    outline,
                    loading: false
                }));
            } catch (error) {
                console.error('Erreur PDF:', error);
                setPdfState(prev => ({...prev, loading: false}));
            }
        };

        loadPdf();
        return () => {
            isSubscribed = false;
            renderTaskRef.current?.cancel();
        };
    }, [pdfUrl]);

    // Rendu de la page
    const renderPage = async (pageNumber) => {
        if (!pdfState.pdfDoc || !canvasRef.current) return;
        renderTaskRef.current?.cancel();

        try {
            const page = await pdfState.pdfDoc.getPage(pageNumber);
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            const viewport = page.getViewport({scale: pdfState.scale});

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            renderTaskRef.current = page.render({
                canvasContext: context,
                viewport
            });

            await renderTaskRef.current.promise;

            // Filigrane
            context.save();
            context.globalAlpha = 0.2;
            context.font = '40px Arial';
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(-45 * Math.PI / 180);
            context.fillStyle = 'black';
            context.textAlign = 'center';
            context.fillText("EMILIB - Bibliothèque numérique", 0, 0);
            context.restore();
        } catch (error) {
            if (error.name !== 'RenderingCancelled') {
                console.error('Erreur rendu:', error);
            }
        }
    };

    // Synthèse vocale
    const handleSpeech = async () => {
        if (isSpeaking) {
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
            return;
        }

        try {
            const page = await pdfState.pdfDoc.getPage(pdfState.currentPage);
            const text = (await page.getTextContent()).items.map(item => item.str).join(' ');

            const utterance = new SpeechSynthesisUtterance(text);
            utterance.lang = 'fr-FR';
            utterance.onend = () => setIsSpeaking(false);

            setIsSpeaking(true);
            window.speechSynthesis.speak(utterance);
            utteranceRef.current = utterance;
        } catch (error) {
            console.error('Erreur lecture:', error);
        }
    };

    useEffect(() => {
        renderPage(pdfState.currentPage);
    }, [pdfState.currentPage, pdfState.scale, pdfState.pdfDoc]);

    // Navigation
    const changePage = (delta) => {
        setPdfState(prev => ({
            ...prev,
            currentPage: Math.min(Math.max(1, prev.currentPage + delta), prev.numPages)
        }));
    };

    const changeScale = (delta) => {
        setPdfState(prev => ({
            ...prev,
            scale: Math.min(Math.max(0.5, prev.scale + delta), 3)
        }));
    };

    return (
        <div className="flex flex-col h-full bg-gray-100">
            <div className="bg-white border-b px-4 py-2 flex justify-between items-center">
                <div className="flex items-center gap-4">
                    <IconButton
                        onClick={() => setIsOutlineOpen(!isOutlineOpen)}
                        icon={
                            <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        }
                        label="Toggle sidebar"
                    />
                    {title && <span className="text-sm font-medium">{title}</span>}
                </div>

                <div className="flex items-center gap-6">
                    {/* Bouton de lecture vocale */}
                    <IconButton
                        onClick={handleSpeech}
                        icon={
                            isSpeaking ? (
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2"/>
                                </svg>
                            ) : (
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M15.536 8.464a5 5 0 010 7.072M17.95 6.05a8 8 0 010 11.9M9.5 6.5v11a1 1 0 001.568.823l7.5-5.5a1 1 0 000-1.646l-7.5-5.5A1 1 0 009.5 6.5z"/>
                                </svg>
                            )
                        }
                        label={isSpeaking ? "Stop" : "Read"}
                    />

                    {/* Contrôles de zoom */}
                    <div className="flex items-center gap-2">
                        <IconButton
                            onClick={() => changeScale(-0.1)}
                            icon={
                                <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor"
                                     viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"/>
                                </svg>
                            }
                            label="Zoom out"
                        />
                        <span>{Math.round(pdfState.scale * 100)}%</span>
                        <IconButton
                            onClick={() => changeScale(0.1)}
                            icon={
                                <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor"
                                     viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M12 4v16m8-8H4"/>
                                </svg>
                            }
                            label="Zoom in"
                        />
                    </div>

                    {/* Navigation des pages */}
                    <div className="flex items-center gap-2">
                        <IconButton
                            onClick={() => changePage(-1)}
                            disabled={pdfState.currentPage === 1}
                            icon={
                                <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor"
                                     viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M15 19l-7-7 7-7"/>
                                </svg>
                            }
                            label="Previous"
                        />
                        <span>
                Page {pdfState.currentPage}/{pdfState.numPages || '?'}
            </span>
                        <IconButton
                            onClick={() => changePage(1)}
                            disabled={pdfState.currentPage === pdfState.numPages}
                            icon={
                                <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor"
                                     viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M9 5l7 7-7 7"/>
                                </svg>
                            }
                            label="Next"
                        />
                    </div>
                </div>
            </div>

            {/* Contenu */}
            <div className="flex flex-1 overflow-hidden">
                {/* Sidebar */}
                <div className={`transition-all duration-300 ${
                    isOutlineOpen ? 'w-72' : 'w-0'
                } bg-white border-r overflow-hidden`}>
                    <div className="h-full overflow-y-auto">
                        <div className="p-4">
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="font-semibold text-gray-800">Table des matières</h3>
                                <button
                                    onClick={() => setIsOutlineOpen(false)}
                                    className="p-1 hover:bg-gray-100 rounded-full lg:hidden"
                                    aria-label="Fermer"
                                >
                                    <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>

                            <OutlineContext.Provider value={{
                                handleOutlineClick: async (item) => {
                                    try {
                                        if (!pdfState.pdfDoc || !item.dest) return;

                                        let dest = item.dest;
                                        if (typeof dest === 'string') {
                                            dest = await pdfState.pdfDoc.getDestination(dest);
                                        }

                                        if (Array.isArray(dest)) {
                                            const pageRef = dest[0];
                                            const pageNum = await pdfState.pdfDoc.getPageIndex(pageRef) + 1;
                                            const page = await pdfState.pdfDoc.getPage(pageNum);
                                            const viewport = page.getViewport({ scale: pdfState.scale });

                                            let yOffset = dest[3] || 0;
                                            if (Array.isArray(dest) && dest.length >= 4) {
                                                yOffset = viewport.height - (dest[3] * viewport.scale);
                                            }

                                            setPdfState(prev => ({
                                                ...prev,
                                                currentPage: pageNum
                                            }));

                                            // Navigation vers la position
                                            setTimeout(() => {
                                                if (containerRef.current) {
                                                    containerRef.current.scrollTo({
                                                        top: yOffset,
                                                        behavior: 'smooth'
                                                    });
                                                }
                                            }, 100);

                                            // Fermer le sidebar sur mobile
                                            if (window.innerWidth < 1024) {
                                                setIsOutlineOpen(false);
                                            }
                                        }
                                    } catch (error) {
                                        console.error('Erreur de navigation:', error);
                                    }
                                }
                            }}>
                                <div className="space-y-1">
                                    {pdfState.outline.length > 0 ? (
                                        pdfState.outline.map((item, index) => (
                                            <OutlineItem
                                                key={index}
                                                item={item}
                                            />
                                        ))
                                    ) : (
                                        <div className="text-gray-500 text-sm italic p-2">
                                            <div className="flex items-center gap-2">
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                </svg>
                                                <span>Aucune table des matières disponible</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </OutlineContext.Provider>

                            {/* Informations supplémentaires */}
                            <div className="mt-6 pt-6 border-t border-gray-200">
                                <div className="text-xs text-gray-500 space-y-2">
                                    <div className="flex items-center gap-2">
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <span>Dernière mise à jour: {new Date().toLocaleDateString()}</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                        </svg>
                                        <span>Total pages: {pdfState.numPages}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* PDF Viewer */}
                <div className="flex-1 relative">
                    <div ref={containerRef} className="absolute inset-0 overflow-auto">
                        <div className="min-h-full flex justify-center p-4">
                            <canvas
                                ref={canvasRef}
                                className="shadow-lg rounded-lg"
                            />
                        </div>
                    </div>

                    {pdfState.loading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-white/80">
                            <div
                                className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PdfViewer;